import type { RouteMeta } from 'vue-router'
import gearlistPageMeta from './gearlist/gearlist.meta'
import subscriberPageMeta from './subscribers/subscribers.meta'
import eusubcriberPageMeta from './EUSubscribers/eusubscribers.meta'

const eventsPageMeta: RouteMeta = {
  name: 'events',
  title: 'Events',
  path: '/events',
  icon: 'fad fa-calendar-star',
  order: 5,
  show: true,
  requiresAuth: false,
  permissions: [],
  children: [eusubcriberPageMeta, subscriberPageMeta, gearlistPageMeta,]
}

export default eventsPageMeta

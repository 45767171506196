import type { RouteMeta } from 'vue-router'
import euEventsSubscribersFormMeta from './form/eu-form-page.meta'
import euEventsSubscribersEntriesMeta from './entries/eu-entries-page.meta'

/**
 * Represents the metadata for the events EU subscribers page.
 */
const eusubscriberPageMeta: RouteMeta = {
  name: 'events.eu-subscribers',
  path: '/events/eu-subscribers',
  title: 'Raffle',
  order: 0,
  show: true,
  requiresAuth: false,
  icon: 'fas fa-ticket',
  permissions: [],
  children: [
    euEventsSubscribersFormMeta,
    euEventsSubscribersEntriesMeta
  ]
}

export default eusubscriberPageMeta

import type { RouteMeta } from 'vue-router'
import salesAffiliatesMeta from '~/features/sales/affiliates/affiliates-page.meta'
import salesGiftcardsMeta from '~/features/sales/giftcards/giftcards-page.meta'
import salesOrdersMeta from '~/features/sales/orders/orders-page.meta'
import salesInvoicesMeta from '~/features/sales/invoices/invoices-page.meta'
import salesCommercialWarrantyInvoiceMeta from '~/features/sales/commercial-warranty-invoice/commercial-warranty-invoice-page.meta'
import salesShipmentsMeta from '~/features/sales/shipments/shipments-page.meta'
import salesCreditMemosMeta from '~/features/sales/credit-memos/credit-memos-page.meta'
import salesProductPricingMeta from '~/features/sales/product-pricing/product-pricing-page.meta'
import salesScanCreditCardSlipMeta from '~/features/sales/scan-credit-card-slip/scan-credit-card-slip-page.meta'
import salesBlackFridayMeta from '~/features/sales/black-friday/black-friday-page.meta'
import salesDealerMapMeta from '~/features/sales/dealer-map/dealer-map-page.meta'
import salesSalesGraphMeta from '~/features/sales/sales-graphs/sales-graph-page.meta'
import salesPointOfSaleSalesMeta from './point-of-sale/point-of-sale-sales-page.meta'
import salesPointOfSaleCustomerMeta from './point-of-sale/point-of-sale-customer-page.meta'

const routes: RouteMeta = {
  name: 'sales',
  title: 'Sales',
  path: '/sales',
  icon: 'fad fa-dollar-sign',
  order: 6,
  show: true,
  requiresAuth: true,
  keepalive: false,
  permissions: [
    'sales point of sale customer | view',
    'sales point of sale sales person | view',
    'sales affiliates | view',
    'sales gift cards | view',
    'sales orders | view',
    'sales returns | view',
    'sales quotes | view',
    'sales invoices | view',
    'sales credits | view',
    'sales payments | view',
    'sales shipping | view',
    'sales discounts | view',
    'sales coupons | view',
    'sales gift cards | view',
    'sales commercial warranty invoice | view',
    'sales credit memos | view',
    'sales shipments | view',
    'sales product pricing | view',
    'sales scan credit card slip | view',
    'sales black friday | view',
    'sales dealer map | view',
    'sales sales graph | view'
  ],
  children: [
    // Point of Sale
    {
      name: 'sales.point-of-sale',
      title: 'Point of Sale',
      path: '/sales/point-of-sale',
      order: 0,
      class: 'bg-fr-gray-350 dark:bg-fr-gray-800',
      expandIcon: 'expand_circle_down',
      show: true,
      requiresAuth: true,
      permissions: [
        'sales point of sale customer | view',
        'sales point of sale sales person | view'
      ],
      children: [salesPointOfSaleSalesMeta, salesPointOfSaleCustomerMeta]
    },
    salesAffiliatesMeta,
    salesGiftcardsMeta,
    salesOrdersMeta,
    salesInvoicesMeta,
    salesCommercialWarrantyInvoiceMeta,
    salesShipmentsMeta,
    salesCreditMemosMeta,
    salesProductPricingMeta,
    salesScanCreditCardSlipMeta,
    salesBlackFridayMeta,
    salesDealerMapMeta,
    salesSalesGraphMeta
  ]
}

export default routes

import type { RouteMeta } from 'vue-router'
import HumanResourcesVacanciesPageMeta from './vacancies/vacancies-page.meta'
import performanceReviewsPageMeta from './performance-reviews/performance-reviews-page.meta'
import clockingCardsPageMeta from './clocking-cards/clocking-cards-page.meta'

const routes: RouteMeta = {
  name: 'human-resources',
  title: 'Human Resources',
  path: '/human-resources',
  icon: 'fad fa-clipboard-user',
  order: 12,
  show: true,
  requiresAuth: true,
  keepalive: false,
  permissions: [
    'human resources clocking cards | view',
    'human resources performance reviews | view',
    'human resources vacancies | view',
    'human resources org chart | view'
  ],
  children: [
    performanceReviewsPageMeta,
    HumanResourcesVacanciesPageMeta,
    clockingCardsPageMeta,
    {
      name: 'human-resources.org-chart',
      title: 'Organizational Chart',
      path: '/human-resources/org-chart',
      order: 3,
      show: true,
      requiresAuth: true,
      permissions: ['human resources org chart | view']
    }
  ]
}

export default routes

import type { RouteMeta } from 'vue-router'
import outfitByVehiclePageMeta from './outfit-by-vehicle/outfit-by-vehicle-page.meta'
import outfitByProductPageMeta from './outfit-by-product/outfit-by-product-page.meta'
import vfitsMakesModelsPageMeta from './makes-models/makes-models-page.meta'

const routes: RouteMeta = {
  name: 'vfits',
  path: '/vfits',
  title: 'Vfits',
  icon: 'fad fa-truck-pickup',
  order: 8,
  requiresAuth: true,
  keepalive: false,
  show: true,
  permissions: [
    'vfits makes and models | view',
    'vfits outfit by product | view',
    'vfits outfit by vehicle | view'
  ],
  children: [
    outfitByVehiclePageMeta,
    outfitByProductPageMeta,
    vfitsMakesModelsPageMeta
  ]
}

export default routes

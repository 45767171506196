<template>
  <div class="flex items-center justify-center !overflow-visible">
    <fr-page
      class="flex h-[50vh] min-h-[600px] w-full min-w-full flex-col flex-nowrap items-center justify-center overflow-visible md:h-[55vh] md:min-h-0 md:w-[80vw] md:min-w-[400px] md:flex-row md:!p-3 lg:h-full lg:w-[70vw] lg:min-w-[70vw] xl:w-[800px] xl:min-w-[800px]"
    >
      <!-- Left side / Top side md -> lg = tablet-->
      <fr-card
        class="!md:rounded-tr-lg relative h-[200px] w-full !rounded-bl-none !rounded-br-none !rounded-tl-lg !rounded-tr-lg !border-b-0 md:h-full md:!rounded-bl-lg md:!rounded-br-none md:!rounded-tl-lg md:!rounded-tr-none md:!border-b-[1px] md:!border-r-0 lg:h-[460px] lg:w-1/2"
      >
        <fr-image
          :src="`${serverURL}/public/landing-image.jpg`"
          class="h-full w-full !rounded-bl-none !rounded-tl-lg !rounded-tr-lg object-cover md:!rounded-bl-lg md:!rounded-tr-none"
        />

        <div
          class="absolute inset-0 z-10 rounded-bl-none rounded-tl-lg bg-gradient-to-t from-black/90 to-black/10 md:rounded-bl-lg md:rounded-tr-none"
        />

        <div
          v-if="!isFetchingDailyQuoteMessage"
          class="absolute bottom-0 z-40 bg-fr-gray-950 bg-opacity-40 p-3 text-white md:!rounded-bl-lg"
        >
          {{ dailyQuoteMessage }}
        </div>

        <div
          v-else
          class="absolute bottom-0 z-40 flex w-full flex-col gap-2 bg-transparent p-3"
        >
          <q-skeleton
            v-for="i in 2"
            :key="`skeleton-${i}`"
            width="100%"
            class="h-4"
          />

          <q-skeleton width="65%" class="h-4" />
        </div>
      </fr-card>

      <!-- Right side / Bottom side -->
      <fr-card
        class="flex h-full w-full flex-col flex-nowrap !rounded-bl-lg !rounded-br-lg !rounded-tl-none !rounded-tr-none md:!rounded-bl-none md:!rounded-tl-none md:!rounded-tr-lg lg:h-[460px] lg:w-1/2"
      >
        <fr-card-header
          show-close-button
          transparent
          class="!flex-row !rounded-tl-none !rounded-tr-none !py-0 md:!rounded-tr-lg"
          :title="$t('login.header')"
          :subtitle="$t('login.subheader')"
        />
        <fr-card-body class="h-full !rounded-bl-lg !rounded-br-lg">
          <TransitionIn>
            <div
              v-if="!user?.id && !isLoggingIn && !isVerifying"
              class="mt-5 flex flex-col items-center justify-center md:h-[80%]"
            >
              <q-form
                v-if="showLoginFields && !isRedirecting"
                key="login-form"
                :data-index="2"
                class="flex w-full flex-1 flex-col items-center gap-3"
                @submit="login()"
              >
                <div class="flex w-full flex-col gap-3 py-6 md:w-4/5">
                  <q-input
                    id="email"
                    v-model.trim="state.email"
                    outlined
                    class="email w-full"
                    :label="$t('login.emailLabel')"
                    :rules="[(val) => !!val || $t('login.emailRequiredLabel')]"
                    lazy-rules="ondemand"
                    type="email"
                    hide-bottom-space
                    required
                    autofocus
                  />
                  <q-input
                    v-model.trim="state.password"
                    outlined
                    class="password w-full"
                    :label="$t('login.passwordLabel')"
                    :rules="[
                      (val) => !!val || $t('login.passwordRequiredLabel')
                    ]"
                    lazy-rules="ondemand"
                    :type="passwordType"
                    hide-bottom-space
                    required
                  >
                    <template #append>
                      <q-icon
                        name="visibility"
                        class="cursor-pointer"
                        @click="
                          () =>
                            (passwordType =
                              passwordType === 'password' ? 'text' : 'password')
                        "
                      />
                    </template>
                  </q-input>

                  <fr-button
                    type="submit"
                    :disabled="isLoggingIn || isVerifying"
                    :loading="isLoggingIn || isVerifying"
                  >
                    <q-icon name="login" />
                    {{ $t('login.buttonLabel') }}
                  </fr-button>
                </div>
              </q-form>

              <div class="flex w-full flex-col items-center justify-center">
                <fr-button
                  v-if="!isRedirecting"
                  variant="outline"
                  :disabled="isLoggingIn || isVerifying"
                  @click="
                    () => {
                      navigateTo(microsoftLoginUrl, { external: true })
                      isRedirecting = true
                    }
                  "
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="21"
                    height="21"
                    viewBox="0 0 21 21"
                  >
                    <rect x="1" y="1" width="9" height="9" fill="#f25022" />
                    <rect x="1" y="11" width="9" height="9" fill="#00a4ef" />
                    <rect x="11" y="1" width="9" height="9" fill="#7fba00" />
                    <rect x="11" y="11" width="9" height="9" fill="#ffb900" />
                  </svg>

                  Sign in with Microsoft
                </fr-button>

                <div v-if="!showLoginFields && !isRedirecting" class="text-xs">
                  <div
                    class="my-3 flex items-center justify-center gap-2 opacity-10 dark:opacity-10"
                  >
                    <hr class="w-16 border-[1px] border-slate-400" />
                    <span>or</span>
                    <hr class="w-16 border-[1px] border-slate-400" />
                  </div>
                  <p
                    class="text-center opacity-10 dark:opacity-10"
                    @click="showLoginFields = true"
                  >
                    login using your email and password
                  </p>
                </div>
              </div>

              <div
                v-if="isRedirecting"
                class="flex w-full flex-1 flex-col items-center justify-center gap-3"
              >
                <q-spinner-cube size="50px" class="text-[#0078D3]" />
                <p>Redirecting...</p>
              </div>
            </div>

            <div
              v-else-if="isLoggingIn || isVerifying"
              key="login-loading"
              :data-index="2"
              class="flex w-full flex-1 flex-col items-center justify-center gap-3"
            >
              <q-spinner-cube size="50px" class="text-[#0078D3]" />
              <p>Signing you in...</p>
            </div>

            <div
              v-else
              key="login-welcome"
              :data-index="2"
              class="relative flex w-full flex-1 flex-col items-center justify-center"
            >
              <user-card :user="user" :wrike-user="wrikeUser" />
            </div>
          </TransitionIn>

          <div
            v-if="showLoginErrorMessage"
            class="mt-5 rounded border border-red-200 bg-red-100/50 p-2 dark:border-red-900 dark:bg-slate-700"
          >
            <p
              class="mx-auto line-clamp-3 text-center text-xs text-red-600 dark:text-red-600"
            >
              {{ loginErrorMessage }}
            </p>
          </div>
        </fr-card-body>
      </fr-card>
    </fr-page>
  </div>
</template>

<script setup lang="ts">
import useAuthStore from '~/features/auth/store'
import { useServiceDeskStore } from '~/features/service-desk/store'
import UserCard from '~/features/auth/components/user-card.vue'

import factories from '~/factories'

/**
 * Confetti - tsparticles
 */
import { z } from 'zod'
import type { QInputProps } from 'quasar'

const serverURL = process.env.FR_PERMISSIONS_URL
const microsoftLoginUrl = serverURL + '/auth/ad'
const router = useRouter()

const showLoginFields = ref<boolean>(false)
const showLoginErrorMessage = ref<boolean>(false)
const isRedirecting = ref<boolean>(false)
const loginErrorMessage = ref<string | null>(null)

const state = reactive<{
  email: string
  emailErorr: boolean
  password: string
  passwordError: boolean
}>({
  email: '',
  emailErorr: false,
  password: '',
  passwordError: false
})

const passwordType = ref<QInputProps['type']>('password')

const emits = defineEmits(['close'])

const loginSchema = z.object({
  email: z.string().email(),
  password: z.string()
})

const authStore = useAuthStore()
const serviceDeskStore = useServiceDeskStore()

// computed
const wrikeUser = computed({
  get: () => serviceDeskStore.wrikeUser,
  set: (value) => (serviceDeskStore.wrikeUser = value)
})

const user = useUser()

const login = async () => {
  const { success } = loginSchema.safeParse({
    email: state.email,
    password: state.password
  })

  if (success) {
    useLogin({
      username: state.email,
      password: state.password
    })
  }
}

const { mutate: useLogin, isPending: isLoggingIn } =
  factories.auth.mutations.useLogin({
    options: {
      onSuccess: () => {
        showLoginErrorMessage.value = false

        setTimeout(() => {
          if (user.value?.id) {
            authStore.showLoginModal = false
            emits('close')
          }
        }, 4000)
      },
      onError: (error) => {
        console.log(error)
        showLoginErrorMessage.value = true
        loginErrorMessage.value = error.message

        // notifier({
        //   message: error.message,
        //   type: 'error'
        // })
      }
    }
  })

const { isSuccess: isSuccessfulVerify, isFetching: isVerifying } =
  factories.auth.queries.useVerifyMe({
    options: {
      enabled: router.currentRoute.value.name === 'verify'
    }
  })

watchEffect(() => {
  if (isSuccessfulVerify.value && router.currentRoute.value.name === 'verify') {
    setTimeout(() => {
      if (user.value?.id) {
        authStore.showLoginModal = false
        router.push('/')
        emits('close')
      }
    }, 4000)
  }
})

/**
 * Daily quote
 */
const { data: dailyQuoteMessage, isFetching: isFetchingDailyQuoteMessage } =
  factories.AI.queries.useGetDailyQuote()
</script>

import type { RouteMeta } from 'vue-router'

/**
 * Represents the metadata for the chatbot page.
 * @auth Requires authentication
 */
const chatbotPageMeta: RouteMeta = {
  name: 'chatbot',
  title: 'Chatbot',
  path: '/chatbot',
  description: {
    name: 'description',
    content: 'Chatbot'
  },
  order: 0,
  requiresAuth: true,
  keepalive: false,
  show: false,
  permissions: []
}

export default chatbotPageMeta

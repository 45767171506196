import type { RouteMeta } from 'vue-router'
import contentLandingPagesMeta from './landing-pages/landing-pages-page.meta'
import contentGearzetteMeta from './gearzette/gearzette-page.meta'
import contentTranslationsM2PwaMeta from './translations/m2pwa/m2pwa-page.meta'

const routes: RouteMeta = {
  name: 'content',
  path: '/content',
  title: 'Content',
  icon: 'fad fa-browser',
  order: 4,
  show: true,
  requiresAuth: true,
  keepalive: false,
  permissions: [
    'content landing pages | view',
    'content gearzette | view',
    'content translations | view'
  ],
  children: [
    contentLandingPagesMeta,
    contentGearzetteMeta,
    contentTranslationsM2PwaMeta
  ]
}

export default routes

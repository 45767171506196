import type { RouteMeta } from 'vue-router'
import authLoginPageMeta from './login/login-page.meta'
import authAccountPageMeta from './account/account-page.meta'
import authLogoutPageMeta from './logout/logout-page.meta'
import authVerifyPageMeta from './verify/verify-page.meta'

/**
 * Represents the metadata for the auth page.
 * @auth Requires authentication
 */
const authPageMeta: RouteMeta = {
  name: 'auth',
  title: 'Auth',
  path: '/auth',
  description: {
    name: 'description',
    content: 'Auth'
  },
  order: 0,
  requiresAuth: true,
  keepalive: false,
  show: true,
  permissions: [],
  children: [
    authLoginPageMeta,
    authLogoutPageMeta,
    authVerifyPageMeta,
    authAccountPageMeta
  ]
}

export default authPageMeta

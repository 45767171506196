<template></template>

<script setup lang="ts">
// If you want to use it in setup, import from the nuxtApp.
const { $pwa } = useNuxtApp()

const $q = useQuasar()

onMounted(() => {
  // if ($pwa?.offlineReady) {
  //   notifier({
  //     message: 'App ready to work offline',
  //     type: 'success'
  //   })
  // } else {
  //   notifier({
  //     message: 'App is not ready to work offline',
  //     type: 'warning'
  //   })
  // }

  if ($pwa?.needRefresh) {
    $q.notify({
      message: 'New content is available',
      timeout: 10000,
      position: 'top',
      actions: [
        {
          label: 'Update',
          handler: () => {
            $pwa?.updateServiceWorker()
          }
        }
      ]
    })
  }
})

// onMounted(() => {
//   if ($pwa.offlineReady)
//   notifier({
//     message: 'Auth failed. or your session has expired. Please log in again.',
//     type: 'error'
//   })
//   toast.success('App ready to work offline')
// })
</script>

<style lang="scss">
.q-dialog {
  color: white;
}
</style>

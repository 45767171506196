import type { PageMeta } from 'nuxt/app'
import usersAdminPageMeta from './users/users-page.meta'
import permissionsAdminPageMeta from './permissions/permissions-page.meta'
import regionsAdminPageMeta from './regions/regions-page.meta'

const AdminPageMeta: PageMeta = {
  name: 'admin',
  title: 'Admin',
  path: '/admin',
  order: 3,
  show: true,
  requiresAuth: true,
  keepalive: false,
  permissions: [
    'admin users | view',
    'admin permissions | view',
    'admin roles | view'
  ],
  class: '',
  icon: 'fad fa-lock',
  children: [usersAdminPageMeta, permissionsAdminPageMeta, regionsAdminPageMeta]
}

export default AdminPageMeta

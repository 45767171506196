import type { RouteMeta } from 'vue-router'
import lostAndFoundPodMeta from './lost-and-found-pod/lost-and-found-pod-page.meta'
import documentSearchMeta from './document-search/document-search-page.meta'
import proofOfDeliveryMeta from './proof-of-delivery/proof-of-delivery-page.meta'

const routes: RouteMeta = {
  title: 'Documents',
  name: 'documents',
  path: '/documents',
  order: 12,
  show: true,
  icon: 'fad fa-file-word',
  requiresAuth: true,
  keepalive: false,
  permissions: [
    'documents lost and found | view',
    'documents search | view',
    'documents proof of delivery | view'
  ],
  children: [documentSearchMeta, proofOfDeliveryMeta, lostAndFoundPodMeta]
}

export default routes

import type { RouteMeta } from 'vue-router'
import eventsSubscribersSettingsMeta from './settings/settings-page.meta'
import eventsSubscribersFormMeta from './form/form-page.meta'
import eventsSubscribersEntriesMeta from './entries/entries-page.meta'

/**
 * Represents the metadata for the events subscribers page.
 */
const subscriberPageMeta: RouteMeta = {
  name: 'events.subscribers',
  path: '/events/subscribers',
  title: 'Subscribers',
  order: 1,
  show: true,
  requiresAuth: false,
  icon: 'fas fa-list-alt',
  permissions: [],
  children: [
    eventsSubscribersFormMeta,
    eventsSubscribersEntriesMeta,
    eventsSubscribersSettingsMeta
  ]
}

export default subscriberPageMeta

import useAuthStore from '~/features/auth/store'

export default function () {
  // const nuxtApp = useNuxtApp()

  const user = computed(() => useAuthStore().user)
  const meta = computed(() => useRoute().meta)

  if (meta.value.requiresAuth && !user.value) return false

  return useCheckPermissions(meta.value?.permissions || [])
}

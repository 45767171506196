import type { RouteMeta } from 'vue-router'
import factoryAssignBarcodeMeta from './assign-barcode/assign-barcode-page.meta'
import factoryShippingDimensionsMeta from './shipping-dimensions/shipping-dimensions-page.meta'
import factoryLabelsFactoryLabelsMeta from './labels/factory-labels/factory-labels-page.meta'
import factoryLabelsProductLabelsMeta from './labels/product-labels/product-labels-page.meta'
import factoryLabelsQRLabelsMeta from './labels/qr-labels/qr-labels-page.meta'

const factoryPageMeta: RouteMeta = {
  name: 'factory',
  path: '/factory',
  title: 'Factory',
  icon: 'fad fa-industry-alt',
  order: 11,
  show: true,
  requiresAuth: true,
  keepalive: false,
  permissions: [
    'factory assign barcode | view',
    'factory shipping dimensions | view',
    'factory labels | view'
  ],
  children: [
    factoryAssignBarcodeMeta,
    factoryShippingDimensionsMeta,
    {
      name: 'factory.labels',
      path: '/factory/labels',
      title: 'Labels',
      order: 2,
      show: true,
      requiresAuth: true,
      icon: 'fad fa-tags',
      permissions: ['factory labels | view'],
      children: [
        factoryLabelsFactoryLabelsMeta,
        factoryLabelsProductLabelsMeta,
        factoryLabelsQRLabelsMeta
      ]
    }
  ]
}

export default factoryPageMeta

<template>
  <div
    class="fr-page flex h-full flex-col items-center justify-center space-y-4 lg:h-screen"
  >
    <h1 class="text-3xl">
      No Access
      <span v-if="$route?.meta?.title" class="text-xl opacity-80"
        >to [{{ $route?.meta?.title }}]</span
      >
    </h1>
    <p>You do not have access to this page</p>

    <div class="flex flex-row gap-2">
      <fr-button to="/">Go Back</fr-button>
      <fr-button to="/auth/login">Login</fr-button>
    </div>
  </div>
</template>

<template>
  <q-layout view="lHh Lpr lFf">
    <!-- App header -->
    <AppHeader />
    <!-- App drawer -->
    <q-drawer
      v-model="showDrawer"
      class="flex flex-col flex-nowrap border-r border-fr-gray-400/80 bg-fr-gray-200 text-fr-gray-950 dark:border-fr-gray-700 dark:bg-fr-gray-850 dark:text-fr-gray-0"
      :width="240"
    >
      <menu-button
        v-if="useDeviceCheck() === 'mobile'"
        class="!absolute right-0 top-0 flex !p-4 lg:hidden"
      />

      <!-- <logo-component
        class="flex items-center justify-center bg-gradient-to-t from-fr-gray-300 from-30% to-blue-400 transition-all duration-300 dark:from-fr-gray-850 dark:to-blue-800"
      /> -->
      <div class="flex w-full items-center justify-center pb-7 pt-4">
        <div class="relative mr-2 flex flex-row items-center gap-2">
          <i class="fa-solid fa-fire fire-text text-2xl"></i>
          <span class="text-2xl font-semibold">Campfire</span>
          <div
            class="absolute bottom-0 right-0 translate-x-1 translate-y-2 transform text-[10px]"
          >
            {{ version }}
          </div>
        </div>
      </div>

      <!--<q-separator class="mb-3" />-->

      <q-scroll-area class="flex-1">
        <q-list link inset-delimiter no-border :dark="$q.dark.isActive">
          <template v-for="item in routes" :key="item.title">
            <menu-item-component
              v-if="!item.children || item.children === null"
              :item="item"
            />
            <expansion-item-component
              v-else-if="item.children && item.children !== null"
              :item="item"
            />
          </template>
        </q-list>
      </q-scroll-area>

      <!-- App Version -->
      <div
        class="flex flex-col items-center justify-center gap-2 p-3 text-center"
      >
        <small
          v-if="!online"
          class="cursor-pointer rounded-full bg-fr-primary-500 p-1 px-2 text-xs"
        >
          Offline
        </small>
        <small class="dark:text-fr-gray-0">
          Nuxt Level
          <q-tooltip anchor="top middle" self="bottom middle">
            <div class="text-center">Version nickname</div>
          </q-tooltip>
        </small>
        <!--<small class="-mt-2 dark:text-fr-gray-0">version {{ version }}</small>-->
      </div>
    </q-drawer>
    <!-- App page -->
    <q-page-container>
      <slot v-if="useCheckAccess()" />
      <fr-no-access-page v-else />
    </q-page-container>
    <!-- Login Model -->
    <client-only>
      <login-modal />
    </client-only>
    <theme-component />
    <pwa-update-prompt />
  </q-layout>
</template>

<script setup lang="ts">
/**
 * ================================================
 * Base Imports
 * ================================================
 */
import { useOnline } from '@vueuse/core'

/**
 * ================================================
 * Local Imports
 * ================================================
 */
import { version } from '../package.json'
import useAuthStore from '~/features/auth/store'

/**
 * ================================================
 * Components Imports
 * ================================================
 */
import AppHeader from './components/navbar-component.vue'
import MenuButton from './components/menu-button-component.vue'
import menuItemComponent from './components/menu-item-component.vue'
import expansionItemComponent from './components/expansion-item-component.vue'
//import logoComponent from './logo-component.vue'
import loginModal from '~/features/auth/components/login-modal.vue'
import PwaUpdatePrompt from '~/features/pwa/components/update-prompt.vue'
import themeComponent from './components/theme-components/theme-component.vue'

/**
 * ================================================
 * Composables
 * ================================================
 */
const $q = useQuasar()
const authStore = useAuthStore()
const route = useRoute()
const routes = useMenuGuard()
const online = useOnline()

/**
 * ================================================
 * Computed
 * ================================================
 */
const showDrawer = computed({
  get: () => authStore.showDrawer,
  set: (val) => (authStore.showDrawer = val)
})

/**
 * ================================================
 * Functions
 * ================================================
 */
const updateOnlineStatus = (e: { type: string }) => {
  const { type } = e
  online.value = type === 'online'

  if (!online.value) {
    notifier({
      message: "You've lost signal, working in offline mode",
      type: 'error'
    })
  } else {
    notifier({
      message: "You're back online",
      type: 'success'
    })
  }
}

const mouseMoveGradient = (e: MouseEvent) => {
  const cards = document.querySelectorAll(
    '.card-hoverable'
  ) as NodeListOf<HTMLElement>

  if (cards) {
    cards.forEach((card) => {
      const rect = card.getBoundingClientRect()

      if (rect) {
        const x = e.pageX
        const y = e.pageY

        const diffX = x - rect.x
        const diffY = y - rect.y

        card.style.setProperty('--x', `${diffX}px`)
        card.style.setProperty('--y', `${diffY}px`)
      }
    })
  }
}

/**
 * ================================================
 * Watchers
 * ================================================
 */
watch(
  () => route.path,
  () => {
    const currentPage = route.path === '/' ? '/dashboard' : route.path

    authStore.currentPage = currentPage
  },
  { immediate: true }
)

/**
 * ================================================
 * Lifecycle Hooks
 * ================================================
 */
onMounted(() => {
  document.addEventListener('mousemove', mouseMoveGradient, false)

  window.addEventListener('online', updateOnlineStatus)
  window.addEventListener('offline', updateOnlineStatus)

  const pageURL = new URL(location.href)
  if (pageURL.searchParams.get('fullscreen') === 'true') {
    authStore.showDrawer = false
    authStore.showHeaderToolbar = false
  }

  console.log(`
   ######     ###    ##     ## ########  ######## #### ########  ########
  ##    ##   ## ##   ###   ### ##     ## ##        ##  ##     ## ##
  ##        ##   ##  #### #### ##     ## ##        ##  ##     ## ##
  ##       ##     ## ## ### ## ########  ######    ##  ########  ######
  ##       ######### ##     ## ##        ##        ##  ##   ##   ##
  ##    ## ##     ## ##     ## ##        ##        ##  ##    ##  ##
   ######  ##     ## ##     ## ##        ##       #### ##     ## ########
  Welcome to Campfire! 🏕️
  Campfire is a platform that allows you to manage all your Frontrunner related tasks in one place.
  `)
})

onBeforeUnmount(() => {
  document.removeEventListener('mousemove', mouseMoveGradient, false)
  window.removeEventListener('online', updateOnlineStatus)
  window.removeEventListener('offline', updateOnlineStatus)
})
</script>

<style lang="sass" scoped>
@mixin fire-text-styles
  -webkit-background-clip: text // For Safari
  background-clip: text
  color: transparent
  display: inline-block // Ensure the text can show the gradient

.fire-text
  background: linear-gradient(to top, #FAB708, #ED1C24)
  @include fire-text-styles

.dark
  .fire-text
    background: linear-gradient(to top, #FFF200, #ED1C24)
    @include fire-text-styles
</style>

import type { RouteMeta } from 'vue-router'
import catalogProductPositionsMeta from './product-positions/product-positions.meta'
import catalogProductSpecsMeta from './product-specs/product-specs.meta'
import catalogDetailsPageMeta from './product-details/product-details.meta'
import catalogProductInfoPageMeta from './product-info/product-info.meta'
import catalogRelationshipsPageMeta from './product-relationships/product-relationships.meta'

const catalogPageMeta: RouteMeta = {
  name: 'catalog',
  title: 'Catalog',
  path: '/catalog',
  icon: 'fad fa-list',
  order: 3,
  show: true,
  requiresAuth: true,
  permissions: [],
  keepalive: false,
  children: [
    catalogDetailsPageMeta,
    catalogProductPositionsMeta,
    catalogRelationshipsPageMeta,
    catalogProductSpecsMeta,
    catalogProductInfoPageMeta
    // catalogProductComparisonMeta,
    // {
    //   name: 'catalog.product-comparison.index',
    //   path: '/product-comparison',
    //   meta: {
    //     title: 'Product Comparison',
    //     order: 3,
    //     show: true,
    //     requiresAuth: true,
    //     permissions: ['catalog product comparison | view']
    //   },
    //   component: () =>
    //     import(
    //       '~/features/catalog/product-comparison/product-comparison-page.vue'
    //     )
    // }
  ]
}

export default catalogPageMeta

<template>
  <q-dialog
    v-model="modal"
    persistent
    :maximized="
      !!(useDeviceCheck() === 'mobile' || useDeviceCheck() === 'tablet')
    "
  >
    <login-card />
  </q-dialog>
</template>

<script setup lang="ts">
import useAuthStore from '~/features/auth/store'
import loginCard from '~/features/auth/components/login-card.vue'

const store = useAuthStore()

const modal = computed({
  get: () => store.showLoginModal,
  set: (value) => (store.showLoginModal = value)
})
</script>

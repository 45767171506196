import type { RouteMeta } from 'vue-router'

import warehouseTransferPageMeta from './warehouse-transfers/warehouse-transfer-page.meta'
import warehousePickingDetailsPageMeta from './picking-details/picking-details-page.meta'
import warehouseLabelsOuterLabelsMeta from './labels/outer-labels/outer-labels-page.meta'
import warehouseLabelsComponentLabelsMeta from './labels/component-labels/component-labels-page.meta'
import warehouseLabelsProductLabelsMeta from './labels/product-labels/product-labels-page.meta'
import warehouseLabelsQRLabelsMeta from './labels/qr-labels/qr-labels-page.meta'
import factoryShippingDimensionsMeta from '~/features/factory/shipping-dimensions/shipping-dimensions-page.meta'
import warehousePickingDashboardMeta from './picking-dashboard/picking-dashboard-page.meta'
import warehouseShippingDetailsMeta from './shipping-details/shipping-details-page.meta'
import warehouseShippingDashboardPageMeta from './shipping-dashboard/shipping-dashboard-page.meta'
import warehouseStockTakePageMeta from './stock-take/stock-take.meta'
import warehouseLabelsCarrierLabelsPageMeta from './labels/carrier-labels/carrier-labels-page.meta'
import warehouseLabelsBinLabelsMeta from './labels/bin-labels/bin-labels-page.meta'
import warehouseLabelsBulkBinLabelsMeta from './labels/bulk-bin-labels/bulk-bin-labels-page.meta'
import warehouseLabelsRetailLabelsMeta from './labels/retail-labels/retail-labels-page.meta'
import warehouseLogisticsContainersMeta from './logistics-containers/logistics-containers-page.meta'
import warehouseMovementsBinTransferMeta from './movements/bin-transfer/bin-transfer-page.meta'
import warehouseMovementsGoodsInTransitMeta from './movements/goods-in-transit/goods-in-transit-page.meta'
import warehouseMovementsImmediateTransferMeta from './movements/immediate-transfer/immediate-transfer-page.meta'

const routes: RouteMeta = {
  name: 'warehouse',
  path: '/warehouse',
  title: 'Warehouse',
  icon: 'fad fa-warehouse-alt',
  order: 9,
  requiresAuth: true,
  keepalive: false,
  show: true,
  permissions: [
    'warehouse picking dashboard | view',
    'warehouse picking details | view',
    'warehouse shipping dashboard | view',
    'warehouse labels | view',
    'warehouse logistics containers | view',
    'warehouse stock take | view',
    'warehouse movements | view',
    'warehouse shipping dimensions | view',
    'warehouse shipping details | view'
  ],
  children: [
    warehousePickingDashboardMeta,
    warehousePickingDetailsPageMeta,
    warehouseTransferPageMeta,
    warehouseShippingDashboardPageMeta,
    warehouseLogisticsContainersMeta,
    // Labels
    {
      name: 'warehouse.labels',
      title: 'Labels',
      path: '/warehouse/labels',
      order: 4,
      requiresAuth: true,
      show: true,
      class: 'bg-fr-gray-350 dark:bg-fr-gray-800',
      expandIcon: 'expand_circle_down',
      icon: 'fad fa-tags',
      permissions: ['warehouse labels | view'],
      children: [
        // Bin Labels
        warehouseLabelsBinLabelsMeta,
        // Bulk Bin Labels
        warehouseLabelsBulkBinLabelsMeta,
        // Carrier Labels
        warehouseLabelsCarrierLabelsPageMeta,
        warehouseLabelsComponentLabelsMeta,
        warehouseLabelsOuterLabelsMeta,
        warehouseLabelsProductLabelsMeta,
        warehouseLabelsQRLabelsMeta,
        // Retail Labels
        warehouseLabelsRetailLabelsMeta
      ]
    },
    // Stock Take
    warehouseStockTakePageMeta,
    // Movements
    {
      name: 'warehouse.movements',
      title: 'Movements',
      path: '/warehouse/movements',
      order: 6,
      requiresAuth: true,
      show: true,
      icon: 'fad fa-arrows-alt',
      permissions: ['warehouse movements | view'],
      class: 'bg-fr-gray-350 dark:bg-fr-gray-800',
      expandIcon: 'expand_circle_down',
      children: [
        warehouseMovementsBinTransferMeta,
        warehouseMovementsGoodsInTransitMeta,
        warehouseMovementsImmediateTransferMeta
      ]
    },
    // Shipping Dimensions
    factoryShippingDimensionsMeta,
    warehouseShippingDetailsMeta
  ]
}

export default routes

import type { RouteMeta } from 'vue-router'
import b2bCustomersMeta from './b2b-customers/b2b-customers-page.meta'
import b2cCustomersMeta from './b2c-customers/b2c-customers-page.meta'
import priceListMeta from './price-list/price-list-page.meta'

const customersPageMeta: RouteMeta = {
  name: 'customers',
  title: 'Customers',
  path: '/customers',
  icon: 'fad fa-users',
  order: 5,
  show: true,
  requiresAuth: true,
  keepalive: false,
  permissions: [
    'customers b2b customers | view',
    'customers b2c customers | view',
    'customers custom price list | view'
  ],
  children: [b2bCustomersMeta, b2cCustomersMeta, priceListMeta]
}

export default customersPageMeta

import loginPageMeta from '~/features/auth/login/login-page.meta'
import dashboardPageMeta from '~/features/dashboard/dashboard-page.meta'
import type { RouteMeta } from 'vue-router'

export default function () {
  const importList = import.meta.glob('~/features/*/*.meta.ts', {
    import: 'default',
    eager: true
  })

  const user = useUser()

  let routesList = Object.entries(importList).map((x) => x[1] as RouteMeta)

  routesList = routesList.filter((route) => route.name !== 'auth')

  routesList = routesList.filter((route) => route.name !== 'dashboard')

  routesList = routesList.filter((route) => route.show)
  routesList = [...routesList].sort((a, b) => (a.order || 0) - (b.order || 0))

  const routes = computed(() =>
    [!user.value?.id ? loginPageMeta : dashboardPageMeta, ...routesList].filter(
      (route) => !route.requiresAuth || (route.requiresAuth && user.value?.id)
    )
  )

  return routes
}

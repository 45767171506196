import type { RouteMeta } from 'vue-router'
import eventsGearlistFormMeta from './form/gearlist-form.meta'
import eventsGearlistSettingsMeta from './settings/gearlist-settings.meta'

/**
 * Represents the metadata for the events subscribers page.
 */
const gearlistPageMeta: RouteMeta = {
  name: 'events.gearlist',
  path: '/events/gearlist',
  title: 'Gearlist',
  description: {
    name: 'description',
    content: 'Events - Gearlist'
  },
  showOnNav: false,
  keepalive: false,
  order: 2,
  show: true,
  icon: 'fas fa-gear',
  requiresAuth: false,
  permissions: [],
  children: [eventsGearlistFormMeta, eventsGearlistSettingsMeta]
}

export default gearlistPageMeta
